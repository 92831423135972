html {
  body {
    background: transparent;

    #application {
      min-height: 100vh;

      [routerLink] {
        cursor: pointer;
      }

      .field-focus {
        outline-width: 2px;
        outline-color: red;
      }

      .hidden {
        display: none !important;
      }

      .href {
        text-decoration: underline;
        color: #0d6efd;

        &:hover {
          color: #0a58ca;
          cursor: pointer;
        }
      }

      .borderless {
        .mat-form-field-infix {
          border-top: 0px !important;
        }
      }
    }

    .dialog {
      .title {
        margin-bottom: 12px;
        border-bottom: 1px solid #e0e0e0;
        display: block;
        padding: 5px;
        font-weight: 600;
      }
      .close {
        position: absolute;
        right: 0px !important;
      }

      mat-slide-toggle {
        button {
          background-color: unset;
        }
      }

      button {
        &:hover {
          opacity: 0.8;
        }
      }
    }

    .scrollbar-styled-smaller {
      scrollbar-color: rgba(0, 0, 0, 0.1);
      scrollbar-width: thin;
    }

    .scrollbar-styled-smaller::-webkit-scrollbar {
      height: 0.4rem;
      width: 0.4rem;
    }

    .scrollbar-styled-smaller::-webkit-scrollbar-track {
      box-shadow: none;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 0.2rem;
      background-color: rgba(0, 0, 0, 0.1);
    }

    .scrollbar-styled-smaller::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.1);
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 0.2rem;
    }

    .scrollbar-styled-smaller::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }

    .scrollbar-styled-smaller::-webkit-scrollbar-thumb:active {
      background-color: rgba(0, 0, 0, 0.3);
    }

    .scrollbar-styled-larger {
      scrollbar-width: auto;
    }

    .scrollbar-styled-larger::-webkit-scrollbar {
      height: 0.6rem;
      width: 0.6rem;
    }

    .scrollbar-styled-larger::-webkit-scrollbar-track {
      box-shadow: none;
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 0.3rem;
      background-color: rgba(0, 0, 0, 0.1);
    }

    .scrollbar-styled-larger::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.1);
      width: 0.6rem;
      height: 0.6rem;
      border-radius: 0.3rem;
    }

    .scrollbar-styled-larger::-webkit-scrollbar-thumb:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }

    .scrollbar-styled-larger::-webkit-scrollbar-thumb:active {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .mat-badge-content {
    text-overflow: clip;
  }

  // Input Fields
  .mdc-text-field--filled .mdc-line-ripple:after {
    border-bottom-color: var(--primary-button);
  }

  .mdc-text-field--outlined:not(.mdc-text-field--disabed).mdc-text-field--focused {
    .mat-mdc-form-field-error {
      color: var(--primary-button);
    }
    [class*="mdc-notched-outline"] {
      border-color: var(--primary-button) !important;
      color: var(--primary-button);
    }
  }
  .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: var(--primary-button);
  }

  .mdc-text-field--invalid {
    [class*="mdc-notched-outline"] {
      border-color: var(--warning-button) !important;
    }
  }

  // Badge
  .mat-badge-accent .mat-badge-content {
    background: var(--filter-color);
    color: #fff;
  }

  // Radio buttons
  .mdc-radio__background .mdc-radio__outer-circle {
    border-color: var(--primary-button);
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background {
    .mdc-radio__outer-circle {
      border-color: var(--filter-color) !important;
    }
    .mdc-radio__inner-circle {
      border-color: var(--filter-color);
    }
  }

  .mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-focus-icon-color: var(--filter-color) !important;
    --mdc-radio-selected-hover-icon-color: var(--brand-color) !important;
    --mdc-radio-selected-icon-color: var(--brand-color) !important;
    --mdc-radio-selected-pressed-icon-color: var(--filter-color) !important;
    --mat-mdc-radio-checked-ripple-color: var(--brand-color) !important;
  }

  .mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
    border-color: var(--filter-color);
  }

  // Toggle buttons
  .mat-mdc-slide-toggle.mat-accent {
    --mdc-switch-selected-focus-state-layer-color: var(--toggle-color);
    --mdc-switch-selected-handle-color: var(--toggle-color);
    --mdc-switch-selected-hover-state-layer-color: var(--toggle-color);
    --mdc-switch-selected-pressed-state-layer-color: var(--toggle-color);
    --mdc-switch-selected-focus-handle-color: var(--toggle-color-focus);
    --mdc-switch-selected-hover-handle-color: var(--toggle-color-focus);
    --mdc-switch-selected-pressed-handle-color: var(--toggle-color-focus);
    --mdc-switch-selected-focus-track-color: var(--toggle-color-highlight);
    --mdc-switch-selected-hover-track-color: var(--toggle-color-highlight);
    --mdc-switch-selected-pressed-track-color: var(--toggle-color-highlight);
    --mdc-switch-selected-track-color: var(--toggle-color-highlight);
  }

  // Buttons
  // unelevated - primary
  .mat-mdc-unelevated-button.mat-primary {
    --mdc-filled-button-container-color: var(--primary-button);
    --mdc-filled-button-label-text-color: #fff;
  }
  // raised - primary
  .mat-mdc-raised-button.mat-primary {
    --mdc-protected-button-container-color: var(--primary-button);
    --mdc-protected-button-label-text-color: #fff;
  }

  // unelevated - accent
  .mat-mdc-unelevated-button.mat-accent {
    --mdc-filled-button-container-color: var(--accent-button);
    --mdc-filled-button-label-text-color: #fff;
  }
  // raised - accent
  .mat-mdc-raised-button.mat-accent {
    --mdc-protected-button-container-color: var(--accent-button);
    --mdc-protected-button-label-text-color: #fff;
  }

  // unelevated - accent
  .mat-mdc-unelevated-button.mat-warn {
    --mdc-filled-button-container-color: var(--warning-button);
    --mdc-filled-button-label-text-color: #fff;
  }
  // raised - accent
  .mat-mdc-raised-button.mat-warn {
    --mdc-protected-button-container-color: var(--warning-button);
    --mdc-protected-button-label-text-color: #fff;
  }

  .mdc-button:not(.text-break) {
    white-space: nowrap;
  }

  // material spinner
  .mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: var(--brand-color);
  }

  // QR ICONS
  .qr_success_icon {
    color: #00aa00;
  }
  .qr_error_icon {
    color: #ff0000;
  }

  .customized-stepper {
    --icon-size: 2.5rem;
    --label-size: 1.125rem;
    --label-size-focus: 1.25rem;
    mat-step-header {
      padding-inline: calc(var(--icon-size) * 0.425);
      .mat-step-icon {
        width: var(--icon-size);
        height: var(--icon-size);
        margin-right: calc(var(--icon-size) / 2);

        mat-icon {
          font-size: calc(var(--icon-size) / 2);
          width: calc(var(--icon-size) / 2);
          height: calc(var(--icon-size) / 2);
        }
      }
      .mat-step-icon-state-done {
        background-color: #00aa00;
      }

      .mat-step-label {
        font-size: var(--label-size);
        &.mat-step-label-selected {
          font-size: var(--label-size-focus);
        }
      }
    }
  }

  .timepickerIcon {
    button {
      svg {
        fill: #707070;
      }
      margin-right: 0.5rem;
    }
  }
}

html.cdk-global-scrollblock {
  .skipToContent {
    display: none;
  }
}

.multiline-tooltip {
  white-space: pre-line;
}
